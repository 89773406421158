import React, { useState, useRef } from "react";
import "./contact.css";

import emailjs from "@emailjs/browser";
import { Box, Typography, Grid, Checkbox, ListItemText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { MuiTelInput } from "mui-tel-input";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

dayjs.extend(customParseFormat);

function CustomDateTimePicker({ label, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        inputFormat="DD/MM/YYYY HH:mm"
        value={value}
        onChange={onChange}
        componentsProps={{
          textField: {
            fullWidth: true,
          },
        }}
      />
    </LocalizationProvider>
  );
}

const Contact = () => {
  const form = useRef();
  const [fullName, setFullName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [service, setService] = useState([]);
  const [primaryDateTime, setPrimaryDateTime] = useState(dayjs());
  const [secondaryDateTime, setSecondaryDateTime] = useState(dayjs());
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handlePhoneNumber = (newValue) => {
    setPhone(newValue);
  };

  const validateEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError(!validateEmail(newEmail));
  };

  const vehicleMakes = {
    car: ["Maruti Suzuki", "Hyundai", "Tata Motors", "Mahindra", "Honda", "Skoda", "Volkswagen", "Renault", "Toyota", "Nissan", "MG Motor", "Ford", "Kia", "Chevrolet", "Fiat", "Mercedes-Benz", "BMW", "Audi", "Lexus", "Jaguar", "Land Rover", "Porsche", "Volvo", "Jeep", "Mitsubishi", "Datsun", "Isuzu", "Mini", "Tesla"],
    bus: ["Olectra", "Volvo", "Ashok Leyland", "Tata", "Eicher", "SML ISUZU", "Mahindra", "JBM", "Force", "Switch Mobility", "Bharat Benz", "Scania", "Eka Mobility"],
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1995; year <= currentYear; year++) {
      years.push(year.toString());
    }
    return years;
  };

  const yearOptions = generateYearOptions();

  const requestedServiceItems = ["General Services", "Oil Change Services", "Engine & Transmission", "A/C Repairs", "Electrical Services", "Tire Repair", "Brake Repair", "Suspension & Safety", "Dents & Painting"];

  const handleServiceChange = (event) => {
    const { value } = event.target;
    setService(typeof value === "string" ? value.split(",") : value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID_1, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_MANAGEMENT_1, form.current, process.env.REACT_APP_EMAILJS_USER_ID_1).then(
      (managementResult) => {
        // After successful management email, send email to the user
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID_1, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_USER_1, form.current, process.env.REACT_APP_EMAILJS_USER_ID_1).then(
          (userResult) => {
            resetFormAndShowMessage();
          },
          (userError) => {
            emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID_2, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_USER_2, form.current, process.env.REACT_APP_EMAILJS_USER_ID_2).then(
              (userWithSecondaryIDResult) => {
                resetFormAndShowMessage();
              },
              (userWithSecondaryIDError) => {
                showErrorMessage();
              }
            );
          }
        );
      },
      (managementError) => {
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID_2, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_MANAGEMENT_2, form.current, process.env.REACT_APP_EMAILJS_USER_ID_2).then(
          (managementWithSecondaryIDResult) => {
            resetFormAndShowMessage();
          },
          (managementWithSecondaryIDError) => {
            showErrorMessage();
          }
        );
      }
    );
  };

  const resetFormAndShowMessage = () => {
    // Reset form fields after successful email submission
    setFullName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setType("");
    setMake("");
    setModel("");
    setYear("");
    setService([]);
    setPrimaryDateTime(dayjs());
    setSecondaryDateTime(dayjs());

    setSnackbarMessage("After a meticulous review by our expert technicians, we'll be in touch with you shortly. Your journey towards impeccable service has just begun!");
    setSnackbarOpen(true);
  };

  const showErrorMessage = () => {
    setSnackbarMessage("Oops! It seems like we hit a bump in the road. Rest assured, our team is on it and we'll steer this back on track soon. Please try again shortly, or feel free to reach out to us directly.");
    setSnackbarOpen(true);
  };

  return (
    <Box component="section" id="contact">
      <Typography variant="h5" sx={{ color: "#0b5b4a", textAlign: "center", my: 2 }}>
        Request an Appointment
      </Typography>
      <form onSubmit={sendEmail} ref={form}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField label="Full Name" variant="outlined" name="fullName" margin="normal" fullWidth required value={fullName} onChange={(e) => setFullName(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField label="Email" variant="outlined" name="email" type="email" margin="normal" fullWidth required error={emailError} helperText={emailError ? "Invalid email address" : ""} value={email} onChange={handleEmailChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MuiTelInput label="Phone Number" variant="outlined" name="phone" margin="normal" value={phone} onChange={handlePhoneNumber} defaultCountry="IN" onlyCountries={["AU", "AE", "CA", "IN", "UK", "US"]} fullWidth required />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField id="outlined-multiline-static" label="Address" name="address" multiline rows={4} fullWidth required value={address} onChange={(e) => setAddress(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="type-label">Vehicle Type</InputLabel>
                <Select labelId="type-label" id="type" value={type} name="type" label="Vehicle Type" onChange={(e) => setType(e.target.value)}>
                  <MenuItem value="car">Car</MenuItem>
                  <MenuItem value="bus">Bus</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="make-label">Vehicle Make</InputLabel>
                <Select
                  labelId="make-label"
                  id="make"
                  name="make"
                  value={make}
                  label="Vehicle Make"
                  onChange={(e) => setMake(e.target.value)}
                  disabled={!type} // Disable if no type is selected
                >
                  {type &&
                    vehicleMakes[type].map((makeOption) => (
                      <MenuItem key={makeOption} value={makeOption}>
                        {makeOption}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField label="Vehicle Model" variant="outlined" name="model" margin="normal" fullWidth required value={model} onChange={(e) => setModel(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="year-label">Vehicle Year</InputLabel>
                <Select labelId="year-label" id="year" value={year} name="year" label="Vehicle Year" onChange={(e) => setYear(e.target.value)}>
                  {yearOptions.map((yearOption) => (
                    <MenuItem key={yearOption} value={yearOption}>
                      {yearOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="service-label">Requested Service</InputLabel>
                <Select labelId="service-label" multiple label="Requested Service" value={service} name="service" onChange={handleServiceChange} renderValue={(selected) => selected.join(", ")} MenuProps={{ PaperProps: { style: { maxHeight: 224 } } }} required>
                  {requestedServiceItems.map((serviceItem) => (
                    <MenuItem key={serviceItem} value={serviceItem}>
                      <Checkbox checked={service.indexOf(serviceItem) > -1} />
                      <ListItemText primary={serviceItem} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CustomDateTimePicker label="Primary Date and Time Choice" value={primaryDateTime} onChange={setPrimaryDateTime} />
              <TextField sx={{ display: "none" }} name="primaryDateTime" value={primaryDateTime.format("DD/MM/YYYY hh:mm A")} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CustomDateTimePicker label="Secondary Date and Time Choice" value={secondaryDateTime} onChange={setSecondaryDateTime} />
              <TextField sx={{ display: "none" }} name="secondaryDateTime" value={secondaryDateTime.format("DD/MM/YYYY hh:mm A")} />
            </Grid>
          </Grid>
          <br />
          <Button type="submit" variant="contained" endIcon={<SendIcon />} className="submit">
            Submit
          </Button>
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
            <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: "100%" }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      </form>
    </Box>
  );
};

export default Contact;
