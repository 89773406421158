import React from "react";
import { styled } from "@mui/material/styles";
import CarRepairSharpIcon from "@mui/icons-material/CarRepairSharp";
import CarRepairOutlinedIcon from "@mui/icons-material/CarRepairOutlined";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Typography, Grid } from "@mui/material";
import choose_us_img from "../../assets/choose_us_img.png";
import FAQs from "./FAQs";

const points = [
  { title: "#1: RAPID, ACCURATE DIAGNOSIS & REPAIR", description: "Gaiety Services does it faster and better! Our auto experts are specially trained and skilled at all auto repairs to provide prompt services." },
  { title: "#2: CONVENIENT SERVICES", description: "Many of our maintenance and repair services are done promptly, meaning our wait times are low. However, there’s no need to wait around while we work on your car. We will follow up with you when the repairs are complete with a call or text a few days after your appointment to ensure that your vehicle is working correctly." },
  { title: "#3: NATIONWIDE PARTS GUARANTEE", description: "Gaiety Services has only Genuine parts." },
  { title: "#4: COMMITTED TO EXCELLENCE", description: "Gaiety Services does it faster and better! Our auto experts are specially trained and skilled at all auto repairs to provide prompt services." },
];
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "none",
    "&.Mui-expanded": {
      transform: "none",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const WhyChooseUs = () => {
  const [expanded, setExpanded] = React.useState("false");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box component="section" id="whyChooseUs">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundImage: `url(${choose_us_img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: { xs: "300px", md: "100%" },
            }}
          >
            {/* Empty box with background image */}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ backgroundColor: "#0b5b4a", p: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ color: "white", textAlign: "center" }}>
            Reasons To Contact GAIETY SERVICES!
          </Typography>
          {points.map((point, index) => (
            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
              <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`} expandIcon={expanded === `panel${index}` ? <CarRepairOutlinedIcon sx={{ fontSize: "1.5rem" }} /> : <CarRepairSharpIcon sx={{ fontSize: "1.5rem" }} />}>
                <Typography sx={{ color: "#0b5b4a" }}>{point.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{point.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 2 }}>
          <Box sx={{ width: { xs: "100%", sm: "80%", md: "60%" }, px: 2 }}>
            <FAQs />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyChooseUs;
