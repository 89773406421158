import React from "react";
import CustomSection from "./CustomSection";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Autoplay, EffectCards } from "swiper";
import { Box, Container, Grid, Typography } from "@mui/material";

const imageCount = 26;
const imagePath = "/vehicle_logos/";
const imageExtension = ".png";

const logos = Array.from({ length: imageCount }, (_, index) => `${imagePath}logo${index + 1}${imageExtension}`);

const BrandShowcase = () => {
  return (
    <>
      <Box sx={{ py: 4 }}>
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ color: "primary.main", textAlign: "center", mb: { xs: 2, md: 0 } }}>
                Explore Our Range of Serviced Brands
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ maxWidth: 200, maxHeight: 200, position: "relative" }}>
                <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards, Autoplay]} autoplay={{ delay: 2000, disableOnInteraction: false }} style={{ width: "100%", height: "100%" }}>
                  {logos.map((logo, index) => (
                    <SwiperSlide key={index}>
                      <div style={{ backgroundColor: "#0b5b4a", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <img src={logo} alt={`Logo ${index + 1}`} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <CustomSection />
    </>
  );
};

export default BrandShowcase;
