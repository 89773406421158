import React from "react";
import "./customSection.css";
import { Box, Grid, Typography, Container } from "@mui/material";
import services_created from "./../../assets/services/create_services.png";
import cards_created from "./../../assets/services/create_cards.png";
import fingertips_created from "./../../assets/services/create_fingertips.png";

const customServices = [
  { img: services_created, title: "We Accept both Cash and Major Credit Cards" },
  { img: cards_created, title: "Proudly Serving the Following Cities: Hyderabad | Surrounding Areas" },
  { img: fingertips_created, title: "Door to Door Service available!" },
];
const CustomSectionBlock = ({ img, title }) => (
  <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
    <Box sx={{ display: "inline-block", mt: 1, mb: 3 }}>
      <img src={img} alt={title} style={{ width: "100px", height: "100px", borderRadius: "50%", boxShadow: "8px 4px 16px #0b5b4a" }} />
    </Box>
    <Typography variant="subtitle1">{title}</Typography>
  </Grid>
);

const CustomSection = () => {
  return (
    <Box sx={{ p: 2, mb: 2 }}>
      <Container>
        <Grid container spacing={2} justifyContent="space-between">
          {customServices.map((customService, index) => (
            <CustomSectionBlock key={index} img={customService.img} title={customService.title} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default CustomSection;
