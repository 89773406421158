import React, { useEffect, useRef } from "react";
import { styled } from "@mui/system";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { buttonClasses } from "@mui/base/Button";

const darkGreen = {
  50: "#e5f2ef",
  100: "#cce5df",
  200: "#99ccbf",
  300: "#66b29f",
  400: "#33a080",
  500: "#0b5b4a",
  600: "#0a5243",
  700: "#09493c",
  800: "#083f34",
  900: "#062f25",
};

export const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
min-width: 400px;
background-color: ${darkGreen[500]};
border-radius: 12px;
margin-bottom: 16px;
display: flex;
align-items: center;
justify-content: flex-start;
overflow-x:auto;
white-space: nowrap;
box-shadow: 0px 4px 6px ${theme.palette.mode === "dark" ? "rgba(0,0,0, 0.4)" : "rgba(0,0,0, 0.2)"};
`
);

export const Tab = styled(({ isSelected, ...otherProps }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  }, [isSelected]);

  return <BaseTab ref={ref} {...otherProps} />;
})`
  font-family: "Poppins", sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${darkGreen[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${darkGreen[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${darkGreen[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
