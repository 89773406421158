import * as React from "react";
import PropTypes from "prop-types";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { styled } from "@mui/system";
import { Popper as BasePopper } from "@mui/base/Popper";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";

export const Select = React.forwardRef(function Select(props, ref) {
  const slots = {
    root: CustomButton,
    listbox: Listbox,
    popper: Popper,
    ...props.slots,
  };

  return <BaseSelect {...props} ref={ref} slots={slots} />;
});

Select.propTypes = {
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
};

const darkGreen = {
  50: "#e5f2ef",
  100: "#cce5df",
  200: "#99ccbf",
  300: "#66b29f",
  400: "#33a080",
  500: "#0b5b4a",
  600: "#0a5243",
  700: "#09493c",
  800: "#083f34",
  900: "#062f25",
};

export const Option = styled(BaseOption)(
  ({ theme }) => `
    display:flex;
    align-items: center;
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionClasses.selected} {
      background-color: ${darkGreen[100]};
      color: ${darkGreen[900]};
    }
  
    &.${optionClasses.highlighted} {
      background-color: ${darkGreen[100]};
      color: ${darkGreen[900]};
    }
  
    &.${optionClasses.highlighted}.${optionClasses.selected} {
      background-color: ${darkGreen[100]};
      color: ${darkGreen[900]};
    }
  
    &.${optionClasses.disabled} {
      color: ${darkGreen[400]};
    }
  
    &:hover:not(.${optionClasses.disabled}) {
      background-color: ${darkGreen[100]};
      color: ${darkGreen[900]};
    }
    & img {
        margin-right: 10px;
        width: 20px; // Set a fixed width for the image
        height: auto;
      }
    `
);

const Popper = styled(BasePopper)`
  z-index: 1400;
`;

const CustomButton = React.forwardRef(function CustomButton(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

CustomButton.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const Listbox = styled("ul")(
  ({ theme }) => `
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    max-height: 140px;
    overflow-y: auto;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${"#fff"};
    border: 1px solid ${darkGreen[200]};
    color: ${darkGreen[900]};
    box-shadow: 0px 2px 4px ${"rgba(0,0,0, 0.05)"};
    @media (max-width: 320px) {
        min-width:224px;
      }
  
      @media (min-width: 321px) and (max-width: 375px) {
        min-width:273px;
      }
  
      @media (min-width: 376px) and (max-width: 425px) {
        min-width:320px;
      }
    `
);

const StyledButton = styled("button", { shouldForwardProp: () => true })(
  ({ theme }) => `
    position: relative;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    width:100%;
    padding: 8px 12px;
    border-radius: 8px;
    text-align: left;
    line-height: 1.5;
    background: ${"#fff"};
    border: 1px solid ${darkGreen[200]};
    color: ${darkGreen[900]};
    box-shadow: 0px 2px 4px ${"rgba(0,0,0, 0.05)"};
  
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${darkGreen[50]};
      border-color: ${darkGreen[300]};
    }
  
    &.${selectClasses.focusVisible} {
      outline: 0;
      border-color: ${darkGreen[400]};
      box-shadow: 0 0 0 3px ${darkGreen[200]};
    }
  
    & > svg {
      font-size: 1rem;
      position: absolute;
      height: 100%;
      top: 0;
      right: 10px;
    }
    `
);
