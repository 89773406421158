import React, { useState, useEffect } from "react";
import "./header.css";
import { Box, Typography, Button } from "@mui/material";

import carousel_1 from "./../../assets/carousel_1.png";
import carousel_2 from "./../../assets/carousel_2.png";
import carousel_3 from "./../../assets/carousel_3.png";
import carousel_4 from "./../../assets/carousel_4.png";
import carousel_5 from "./../../assets/carousel_5.png";
import carousel_sankrathi from "../../assets/festivals/carousel_sankranthi.png";
import carousel_republic from "../../assets/festivals/carousel_republic.png";
import carousel_valentines from "../../assets/festivals/carousel_valentines.png";
import carousel_ramzan from "../../assets/festivals/carousel_ramzan.png";
import carousel_ugadi from "../../assets/festivals/carousel_ugadi.png";
import carousel_holi from "../../assets/festivals/carousel_holi.png";
import carousel_independence from "../../assets/festivals/carousel_independence.png";
import carousel_ganesh from "../../assets/festivals/carousel_ganesh.png";
import carousel_dussehra from "../../assets/festivals/carousel_dusheera.png";
import carousel_diwali from "../../assets/festivals/carousel_diwali.png";
import carousel_christmas from "../../assets/festivals/carousel_christmas.png";
import carousel_newyear from "../../assets/festivals/carousel_newyear.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

// Import Swiper modules
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";

// Install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination]);

const getCurrentEvent = () => {
  const today = new Date();
  const month = today.getMonth();
  const date = today.getDate();

  if (month === 0 && date >= 7 && date < 19) {
    return { src: carousel_sankrathi, alt: "Sankrathi", text: "Wishing You A Very Happy Makar Sankranti" };
  } else if (month === 0 && date >= 23 && date < 28) {
    return { src: carousel_republic, alt: "Republic", text: "Wishing you a glorious Republic Day." };
  } else if (month === 1 && date >= 10 && date < 16) {
    return { src: carousel_valentines, alt: "Valentines", text: "Happy Valentine's Day." };
  } else if (month === 2 && date >= 10 && date < 20) {
    return { src: carousel_ramzan, alt: "Ramzan", text: "رَمَضان مُبارَك Ramadan Mubarak." };
  } else if (month === 2 && date >= 20 && date < 28) {
    return { src: carousel_holi, alt: "Holi", text: "Wishing you and your family success, happiness and prosperity this Holi and always" };
  } else if (month === 3 && date >= 6 && date < 12) {
    return { src: carousel_ugadi, alt: "Ugadi", text: "Happy Ugadi." };
  } else if (month === 7 && date >= 11 && date < 19) {
    return { src: carousel_independence, alt: "Independence", text: "Happy Independence Day." };
  } else if (month === 8 && date >= 4 && date < 16) {
    return { src: carousel_ganesh, alt: "Vinayak Chaturthi", text: "Wishing you a Happy Vinayak Chaturthi." };
  } else if (month === 9 && date >= 1 && date < 15) {
    return { src: carousel_dussehra, alt: "Dussehra", text: "Wishing you a very Happy Dussehra!." };
  } else if (month === 9 && date >= 27 && date < 31) {
    return { src: carousel_diwali, alt: "Diwali", text: "Wishing you a very Happy Diwali!." };
  } else if (month === 10 && date >= 1 && date < 4) {
    return { src: carousel_diwali, alt: "Diwali", text: "Wishing you a very Happy Diwali!." };
  } else if (month === 11 && date >= 15 && date < 26) {
    return { src: carousel_christmas, alt: "Christmas", text: "Merry Christmas!." };
  } else if (month === 11 && date >= 27 && date < 31) {
    return { src: carousel_newyear, alt: "New Year", text: "Wishing you a Happy New Year and blessed year ahead." };
  }
  return { src: carousel_5, alt: "Carousel 5", text: "Efficient Repair Services" };
};

const Header = () => {
  const [activeSlideText, setActiveSlideText] = useState("Your Trusted Auto Repair Experts");

  const initialCarouselItem = getCurrentEvent();
  const [carousel5Item, setCarousel5Item] = useState(initialCarouselItem);

  useEffect(() => {
    const interval = setInterval(() => {
      setCarousel5Item(getCurrentEvent());
    }, 86400000);

    return () => clearInterval(interval);
  }, []);

  const carouselItems = [{ src: carousel_1, alt: "Carousel 1", text: "Your Trusted Auto Repair Experts" }, { src: carousel_2, alt: "Carousel 2", text: "Quality Service, Affordable Price" }, { src: carousel_3, alt: "Carousel 3", text: "We Care About Your Vehicle" }, { src: carousel_4, alt: "Carousel 4", text: "Professional and Reliable" }, carousel5Item];

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.realIndex;
    const newText = carouselItems[activeIndex].text;
    setActiveSlideText(newText);
  };

  return (
    <Box sx={{ overflow: "hidden", textAlign: "center", position: "relative" }}>
      <Swiper
        centeredSlides={true}
        navigation={true}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        onSlideChange={handleSlideChange}
      >
        {carouselItems.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item.src} alt={item.alt} style={{ width: "100%", objectFit: "cover" }} />
          </SwiperSlide>
        ))}
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: { xs: "100%", sm: "50%" },
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              height: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              px: { sm: 2, md: 4 },
              py: 2,
            }}
          >
            <Typography
              textAlign="center"
              sx={{
                color: "#111",
                fontFamily: '"Poppins", "sans-serif"',
                fontSize: { xs: "1rem", sm: "2rem", md: "3rem" },
                fontWeight: 700,
                maxWidth: { xs: "100%", sm: "100%", md: "80%" },
                overflow: "hidden", // Ensuring the text doesn't overflow
                textOverflow: "ellipsis", // Adding ellipsis if text is too long
                display: "-webkit-box", // Necessary for line clamping
                WebkitLineClamp: 4, // Limit text to 3 lines
                WebkitBoxOrient: "vertical", // Vertical orientation for line clamp
                margin: "0 auto", // Centering the text block
              }}
            >
              {activeSlideText}
            </Typography>
          </Box>
          <Box
            sx={{
              height: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button variant="contained" href="#contact" sx={{ backgroundColor: "#0b5b4a", color: "fffff9" }}>
              Request an appointment
            </Button>
          </Box>
        </Box>
      </Swiper>
    </Box>
  );
};

export default Header;
