import React, { useState, useRef, useEffect } from "react";
import { AppBar, Toolbar, Typography, Box, Button, Drawer, IconButton, Container, Avatar, Fab, Link } from "@mui/material";

import logo from "../../assets/appLogo.png";

import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HomeIcon from "@mui/icons-material/Home";
import UserIcon from "@mui/icons-material/Person";
import ServicesIcon from "@mui/icons-material/Build";
import CarRentalIcon from "@mui/icons-material/CarRental";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(true);

  const timeOutRef = useRef(null);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerNavClick = (href) => {
    window.location.href = href; // Manually handling navigation
  };

  /* Time out for active in Nav for larger screens */
  const handleNavClick = (navId) => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    setActiveNav(navId);
    timeOutRef.current = setTimeout(() => {
      setActiveNav(null);
    }, 5000);
  };
  useEffect(() => {
    const handleScroll = () => {
      setShowContactInfo(window.scrollY < 50);

      if (window.scrollY > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
      // Remove the scroll event listener
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const navItems = [
    { href: "/#", label: "Home", icon: <HomeIcon /> },
    { href: "/#about", label: "About Us", icon: <UserIcon /> },
    { href: "/#services", label: "Services", icon: <ServicesIcon /> },
    { href: "/#whyChooseUs", label: "Why Choose Us", icon: <CarRentalIcon /> },
    { href: "/#contact", label: "Contact", icon: <ConnectWithoutContactIcon /> },
  ];

  return (
    <>
      <AppBar position="sticky" color="transparent" sx={{ backdropFilter: "blur(15px)" }}>
        {showContactInfo && (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "end",
              alignItems: "center",
              py: { xs: 0.5, sm: 1 },
              px: { xs: 1, sm: 2 },
              bgcolor: "primary.main",
              "& > *": {
                ml: { xs: 1, sm: 2 },
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", mb: { xs: 1, sm: 0 } }}>
              <PhoneIcon sx={{ color: "light.main", mr: 1 }} />
              <Link href="tel:+919966662657" target="_blank" rel="noopener noreferrer" sx={{ display: "flex", alignItems: "center", color: "light.main", textDecoration: "none" }}>
                <Typography variant="body2">: +91 99666 62657</Typography>
              </Link>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <EmailIcon sx={{ color: "light.main", ml: 2, mr: 1 }} />
              <Link href="mailto:contact@gaietyservice.com" target="_blank" rel="noopener noreferrer" sx={{ display: "flex", alignItems: "center", color: "light.main", textDecoration: "none" }}>
                <Typography variant="body2">: contact@gaietyservice.com</Typography>
              </Link>
            </Box>
          </Box>
        )}
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Logo for all screens */}
            <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center", mr: 1 }}>
              <a href="/" style={{ textDecoration: "none", color: "black", display: "flex", alignItems: "center" }}>
                <Avatar alt="logo" src={logo} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "1rem", md: "1.25rem" },
                    ml: 1,
                    "&:hover": {
                      color: "black",
                      textDecoration: "none",
                    },
                  }}
                >
                  Gaiety Services
                </Typography>
              </a>
            </Box>
            {/* Navigation items for larger screens */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "flex-end" }}>
              {navItems.map((item) => (
                <Button
                  key={item.label}
                  href={item.href}
                  onClick={() => handleNavClick(item.href)}
                  sx={{
                    color: activeNav === item.href ? "light.main" : "dark.main",
                    backgroundColor: activeNav === item.href ? "primary.main" : "transparent",
                    border: activeNav === item.href ? "primary.main" : "transparent",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "light.main",
                      border: "primary.main",
                    },
                    ml: 2,
                  }}
                  startIcon={item.icon}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
            {/* Navigation items for smaller screens */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, justifyContent: "flex-end" }}>
              <IconButton size="large" onClick={handleDrawerOpen} color="dark.main">
                <MenuIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </Box>
            <Drawer
              anchor="top"
              open={drawerOpen}
              onClose={handleDrawerClose}
              sx={{ backgroundColor: "primary.main" }}
              PaperProps={{
                sx: {
                  boxShadow: "none",
                },
              }}
            >
              {/* Header with Close Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "10px 15px",
                }}
              >
                <IconButton onClick={handleDrawerClose}>
                  <CloseIcon sx={{ color: "light.main" }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: "40px",
                  height: "100%",
                }}
              >
                {navItems.map((item) => (
                  <Typography
                    key={item.label}
                    variant="h6"
                    noWrap
                    sx={{
                      fontSize: "28px",
                      color: "light.main",
                      padding: "10px 15px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    component="a"
                    href={item.href}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDrawerNavClick(item.href);
                      handleDrawerClose();
                    }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </Box>
            </Drawer>
          </Toolbar>
        </Container>
      </AppBar>
      {showScrollTop && (
        <Fab size="small" aria-label="scroll back to top" sx={{ position: "fixed", bottom: { xs: 16, sm: 20 }, right: { xs: 16, sm: 20 }, backgroundColor: "primary.main" }} onClick={scrollToTop}>
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </>
  );
};

export default Nav;
