import React, { useState } from "react";
import "./services.css";
import { Box, Grid, Typography, Container, Modal, IconButton, Button } from "@mui/material";
import { Tab, TabsList } from "./../../themes/tabTheme.js";
import { Tabs } from "@mui/base/Tabs";
import { Select, Option } from "./../../themes/selectTheme.js";

import engineOil from "../../assets/services/eo.png";
import tiresImage from "../../assets/services/cw.png";
import brakeRepairImage from "../../assets/services/brake.png";
import engineImage from "../../assets/services/en.png";
import electricalImage from "../../assets/services/es.png";
import generalServicesImage from "../../assets/services/wrench.png";
import acRepairImage from "../../assets/services/ac.png";
import paintImage from "../../assets/services/cp.png";
import suspensionImage from "../../assets/services/ss.png";

// import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import CarRepairSharpIcon from "@mui/icons-material/CarRepairSharp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CallIcon from "@mui/icons-material/Call";

const services = [
  { img: generalServicesImage, title: "General Services", description: ["Oil Filter Change", "Fleet Filter Inspection and Replacement", "Air filter Inspection and Replacement", "Coolant check and Top-up", "Drive Shaft Boots Inspection", "Fans and A/C Belt Inspection", "Complete Undercarriage checkup", "4-Wheel Break Checkup and Cleaning", "Doors Lubrication", "Battery Checkup", "Terminal lubrication", "Wiper Blade Replacement", "Water Wash (Additional)"] },
  { img: engineOil, title: "Oil Change Services", description: ["Belts", "Brake System Flush", "Coolant System Flush", "Differential Flush", "Fuel Injection Cleaning", "Headlight & Bulb Replacement", "Hoses", "Power Steering Flush", "Radiator Flush", "Transmission Flush", "Complimentary Vehicle Inspection ***"] },
  { img: engineImage, title: "Engine & Transmission", description: ["Engine Maintenance", "Engine Repair", "Engine Replacement", "Timing Belt", "Timing Chain", "Transmission Flush", "Transmission Maintenance", "Transmission Repair", "Transmission Replacement", "Fuel System Injector Cleaning", "Serpentine Belt Replacement", "Clutch Replacement"] },
  { img: acRepairImage, title: "A/C Repairs", description: ["A/C Leaks Repair", "A/C Belts", "Coolant System Flush", "Radiator Flush", "Hoses"] },
  { img: electricalImage, title: "Electrical Services", description: ["Radiators", "Alternator", "Battery Works", "Starter Repair", "Power Windows", "Power Steering", "Windshield Repairs", "Defroster"] },
  { img: tiresImage, title: "Tire Repair", description: ["Wheel Balancing", "Tire Replacement", "Wheel Alignment", "Tire Pressure Check", "Tire Mounting", "Tire Recycling", "Valve Stem Replacement", "Retreading Services (For Buses)", "Fleet Management Services (For Buses)", "Heavy-Duty Tire Services (For Buses)"] },
  { img: brakeRepairImage, title: "Brake Repair", description: ["Anti-Lock Brake System", "Brake Fluid Flushes", "Brake Hoses", "Brake Pad & Shoe Replacement", "Caliper Replacement", "Resurface Rotors"] },
  { img: suspensionImage, title: "Suspension & Safety", description: ["Shock and Struct Replacement", "Suspension Bushing Replacement", "Ball Joint Service", "BrakeSystem Check", "Tire Thread and Pressure Inspection", "Seat Belt and Airbag System Checks", "Windshield and Mirror Inspection", "Steering System Assessment"] },
  { img: paintImage, title: "Dents & Painting", description: ["Complete Vehicle Painting", "Dent Removal", "Interior Detailing", "Teflon Coating", "Body Damage Repair", "Scratch Removal", "Bumper Repair/Replacement"] },
];

const Services = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleOpen = (service, index) => {
    setSelectedService(service);
    setSelectedTab(index);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDropdownChange = (event, newValue) => {
    event.stopPropagation();
    const newSelectedTab = event.target.value;
    setSelectedTab(newValue);
    setSelectedService(services[newSelectedTab]);
  };

  const handleRequestCallback = () => {
    setOpenModal(false);
    window.location.href = "#contact";
  };

  return (
    <Box component="section" id="services">
      <Container maxWidth="md">
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Typography variant="h5" sx={{ color: "primary.main", mb: 3 }} gutterBottom>
            Professional Services
          </Typography>
          <Typography>At Gaiety Service, we pride ourselves on offering comprehensive, expert car servicing and repairs. Our commitment is not just to your car's wellbeing but also to ensuring your complete satisfaction. Discover the diverse array of services our center provides, each designed to meet your vehicle's every need:</Typography>
        </Box>
        <Grid container spacing={2} justifyContent="space-between">
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ textAlign: "center" }}>
              <Box
                onClick={() => handleOpen(service, index)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                <Box sx={{ display: "inline-block", mt: 1, mb: 1 }}>
                  <img src={service.img} alt={service.title} style={{ width: "75px", height: "75px" }} />
                </Box>
                <Typography variant="subtitle1">
                  {service.title} <ArrowForwardIcon sx={{ fontSize: "medium", color: "red" }} />
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: "center", mb: 2, mt: 4 }}>
          <Typography>Our repair services are infused with a personal touch, ensuring that every customer feels valued and respected. At Gaiety Service, you're not just another number in line — you're a part of our community, where individual care and attention are our top priorities.</Typography>
        </Box>
        {/* <Box sx={{ mt: 1, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", color: "red" }}>
          <InfoIcon sx={{ mr: 1 }} />
          <Typography>Please click on services for more information</Typography>
        </Box> */}
      </Container>
      {selectedService && (
        <Modal open={openModal} onClose={handleClose} aria-labelledby="service-modal-title" aria-describedby="service-modal-description">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: "70%", md: "50%" },
              backgroundColor: "#f1f3f4",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: "none",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                left: 16,
                top: 8,
                color: "primary.main",
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* Always render both components */}
            <Box sx={{ display: { xs: "none", sm: "block" }, mt: 3 }}>
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <TabsList>
                  {services.map((service, index) => (
                    <Tab value={index} key={index} isSelected={selectedTab === index}>
                      {service.title}
                    </Tab>
                  ))}
                </TabsList>
              </Tabs>
            </Box>

            <Box sx={{ display: { xs: "flex", sm: "none" }, flexDirection: "column", justifyContent: "center", alignItems: "center", mt: 3 }}>
              <Select value={selectedTab} onChange={handleDropdownChange}>
                {/* Dropdown items for each service */}
                {services.map((service, index) => (
                  <Option value={index} key={index}>
                    <img loading="lazy" width={20} height={14} srcSet={service.img} src={service.img} alt={service.title} />
                    {service.title}
                  </Option>
                ))}
              </Select>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Box component="ul" sx={{ pl: 3, listStyleType: "none" }}>
                {selectedTab !== null &&
                  services[selectedTab] &&
                  services[selectedTab].description.map((point, index) => (
                    <Box component="li" key={index} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <CarRepairSharpIcon sx={{ mr: 1, color: "primary.main" }} />
                      <Typography variant="body1">{point}</Typography>
                    </Box>
                  ))}
              </Box>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                pt: 2,
              }}
            >
              <Typography variant="body1" sx={{ mb: 2 }}>
                Want to know more about our services or pricing?
              </Typography>
              <Button variant="contained" color="error" onClick={handleRequestCallback} endIcon={<CallIcon />}>
                Request a Call Back
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Services;
