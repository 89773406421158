import React from "react";
import "./footer.css";
import { Box, Grid, Typography, Container, Link } from "@mui/material";
import { AiFillGoogleSquare } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import logo from "../../assets/appLogo.png";
import backgroundImage from "../../assets/carousel-3.avif";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const Footer = () => {
  const whatsAppNumber = "+919966662657";

  const getDayOfWeek = () => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[new Date().getDay()];
  };

  const currentDay = getDayOfWeek();

  return (
    <Box
      component="footer"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        color: "#000",
        padding: "3rem 0",
        marginTop: "4rem",
      }}
    >
      <Container maxWidth="lg" sx={{ padding: "1rem", backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
        {/* Logo Block */}
        <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mb: 2 }}>
          <Link
            href="#"
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.2rem",
              "&:visited": {
                color: "#0b5b4a",
              },
              "&:hover": {
                color: "#0b5b4a",
              },
            }}
          >
            <img src={logo} alt="Logo" style={{ height: "30px", width: "auto", marginRight: "10px" }} />
            <span>Gaiety Services</span>
          </Link>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          {/* Links Block */}
          <Grid item xs={12} sm={12} md={2} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ marginBottom: "1rem", textDecoration: "underline" }}>
              Quick Links
            </Typography>
            <Box sx={{ textAlign: { xs: "center", sm: "center", md: "left" } }}>
              <Link
                href="/#about"
                underline="none"
                sx={{
                  display: "block",
                  "&:visited": {
                    color: "#0b5b4a",
                  },
                  "&:hover": {
                    color: "#0b5b4a",
                  },
                }}
              >
                About
              </Link>
              <Link
                href="/#experience"
                underline="none"
                sx={{
                  display: "block",
                  "&:visited": {
                    color: "#0b5b4a",
                  },
                  "&:hover": {
                    color: "#0b5b4a",
                  },
                }}
              >
                Experience
              </Link>
              <Link
                href="/#services"
                underline="none"
                sx={{
                  display: "block",
                  "&:visited": {
                    color: "#0b5b4a",
                  },
                  "&:hover": {
                    color: "#0b5b4a",
                  },
                }}
              >
                Services
              </Link>
              <Link
                href="/#whyChooseUs"
                underline="none"
                sx={{
                  display: "block",
                  "&:visited": {
                    color: "#0b5b4a",
                  },
                  "&:hover": {
                    color: "#0b5b4a",
                  },
                }}
              >
                Why Choose Us
              </Link>
              <Link
                href="/#contact"
                underline="none"
                sx={{
                  display: "block",
                  "&:visited": {
                    color: "#0b5b4a",
                  },
                  "&:hover": {
                    color: "#0b5b4a",
                  },
                }}
              >
                Contact
              </Link>
            </Box>
          </Grid>
          {/* Contact Block */}
          <Grid item xs={12} sm={12} md={3}>
            <Typography variant="h6" sx={{ marginBottom: "1rem", textDecoration: "underline", textAlign: "center" }}>
              Contact
            </Typography>

            {/* Address */}
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" }, textAlign: "center", marginBottom: "0.5rem" }}>
              <LocationOnIcon sx={{ color: "primary.main", marginRight: { md: "0.5rem" }, marginBottom: { xs: "0.5rem", md: 0 } }} />
              <Typography sx={{ textAlign: { xs: "center", sm: "center", md: "left" } }}>8-3-318/9/6, Jayaprakash Nagar, Yellareddyguda, Hyderabad, Telangana - 500073.</Typography>
            </Box>

            {/* Phone */}
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" }, textAlign: "center", marginBottom: "0.5rem" }}>
              <PhoneIcon sx={{ color: "primary.main", marginRight: { md: "0.5rem" }, marginBottom: { xs: "0.5rem", md: 0 } }} />
              <Link
                href="tel:+919966662657"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textAlign: "center",
                  color: "dark.main",
                  "&:visited": {
                    color: "dark.main",
                  },
                  "&:hover": {
                    color: "dark.main",
                  },
                  textDecoration: "none",
                }}
              >
                <Typography>+91 99666 62657</Typography>
              </Link>
            </Box>

            {/* Email */}
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" }, textAlign: "center" }}>
              <EmailIcon sx={{ color: "primary.main", marginRight: { md: "0.5rem" }, marginBottom: { xs: "0.5rem", md: 0 } }} />
              <Link
                href="mailto:contact@gaietyservice.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textAlign: "center",
                  color: "dark.main",
                  "&:visited": {
                    color: "dark.main",
                  },
                  "&:hover": {
                    color: "dark.main",
                  },
                  textDecoration: "none",
                }}
              >
                <Typography>contact@gaietyservice.com</Typography>
              </Link>
            </Box>
          </Grid>

          {/* Hours of Operations Block */}
          <Grid item xs={12} sm={12} md={3} sx={{ textAlign: { xs: "center", sm: "center", md: "center" } }}>
            <Typography variant="h6" sx={{ marginBottom: "1rem", textDecoration: "underline" }}>
              Hours of Operations
            </Typography>
            <Box component="ul" sx={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day) => (
                <Grid container key={day} component="li" sx={{ color: currentDay === day ? "primary.main" : "inherit", fontWeight: currentDay === day ? "bold" : "inherit" }}>
                  <Grid item xs={6}>
                    {day}
                  </Grid>
                  <Grid item xs={6}>
                    11 AM - 10 PM
                  </Grid>
                </Grid>
              ))}
              <Grid container component="li" sx={{ color: currentDay === "Sunday" ? "primary.main" : "inherit" }}>
                <Grid item xs={6}>
                  Sunday
                </Grid>
                <Grid item xs={6}>
                  Closed
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Socials Block */}
          <Grid item xs={12} sm={12} md={2} sx={{ textAlign: { xs: "center", sm: "center", md: "center" } }}>
            <Typography variant="h6" sx={{ marginBottom: "1rem", textDecoration: "underline" }}>
              Socials
            </Typography>
            <Box sx={{ display: "flex", justifyContent: { xs: "center", sm: "center", md: "center" }, flexWrap: "wrap" }}>
              <Link
                href="https://www.google.com/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  padding: "0.8rem",
                  borderRadius: "0.7rem",
                  display: "flex",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                <AiFillGoogleSquare />
              </Link>
            </Box>
            <Box sx={{ display: "flex", justifyContent: { xs: "center", sm: "center", md: "center" }, flexWrap: "wrap" }}>
              <Link
                href={`https://wa.me/${whatsAppNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  padding: "0.8rem",
                  borderRadius: "0.7rem",
                  display: "flex",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                <RiWhatsappFill />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ textAlign: "center", color: "var(--color-light)" }}>
        <small>&copy; Gaiety @{new Date().getFullYear()}. All Rights Reserved</small>
      </Box>
    </Box>
  );
};

export default Footer;
