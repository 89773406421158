import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import CarRepairSharpIcon from "@mui/icons-material/CarRepairSharp";
import CarRepairOutlinedIcon from "@mui/icons-material/CarRepairOutlined";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "none",
    "&.Mui-expanded": {
      transform: "none",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// Component for the FAQ section
const FAQs = () => {
  const [expanded, setExpanded] = React.useState("false");
  const [expandedDialog, setExpandedDialog] = React.useState("false");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleDialogChange = (panel) => (event, newExpanded) => {
    setExpandedDialog(newExpanded ? panel : false);
  };

  // Function to handle dialog open
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Function to handle dialog close
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogContactClick = () => {
    setDialogOpen(false);
    window.location.href = "#contact";
  };
  // Your initial 3 FAQ points
  const initialFAQs = [
    { title: "1. What types of vehicles do you repair?", description: "Gaiety Services specialize in repairing both cars and buses, including various makes and models." },
    { title: "2. What services do you offer for vehicle repairs?", description: "Our services include engine repairs, brake replacements, tire services, electrical repairs, suspension work, and more. You can check more in Services section." },
    { title: "3. Can I wait at your shop while my vehicle is being repaired?", description: "No need to wait at the shops anymore; Gaiety Services will perform repairs at your doorstep." },
    { title: "4. How can I schedule an appointment for repairs?", description: "You can schedule an appointment by calling our service center, visiting our website, or using our online appointment booking system." },
  ];

  // Additional FAQ points for the dialog
  const additionalFAQs = [
    { title: "5. What is your turnaround time for common repair jobs?", description: "Turnaround times can vary depending on the type of repair, but we strive to complete most common repairs within the same day." },
    { title: "6. Do you use genuine or aftermarket parts for repairs?", description: "We use a combination of genuine and high-quality aftermarket parts, ensuring the best value and performance for your vehicle." },
    { title: "7. What payment methods do you accept?", description: "We accept various payment methods, including cash, credit cards, debit cards, and digital payment options like UPI and mobile wallets." },
    { title: "8. Are your technicians certified and experienced?", description: "Our technicians are highly trained, certified, and have extensive experience in repairing cars and buses. We continuously invest in their training and skills." },
    { title: "9. Do you offer warranties on your repair work?", description: "Yes, we provide warranties on both parts and labor for many of our repair services. Please ask for details when scheduling your repair." },
    { title: "10. Can I get a cost estimate for the repair work needed?", description: "Of course! We provide free, no-obligation cost estimates for all repair jobs. contact us with details." },
    { title: "11. What should I do in case of a breakdown on the road?", description: " In the event of a breakdown, safely pull over to the side of the road, turn on hazard lights, and contact our emergency hotline for assistance." },
    { title: "12. Do you provide emergency repair services?", description: "Yes, Gaiety Services offer emergency repair services for situations like breakdowns and accidents. Contact our hotline or fill the contact form for immediate assistance." },
    { title: "13. What safety measures do you have in place during the COVID-19 pandemic?", description: "We have implemented strict safety protocols to protect both our staff and customers, including sanitization, social distancing, and contactless service options." },
  ];

  return (
    <>
      {/* Initial FAQs */}
      <Typography variant="h5" sx={{ color: "#0b5b4a", textAlign: "center" }}>
        FAQs
      </Typography>
      {initialFAQs.map((faq, index) => (
        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
          <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`} expandIcon={expanded === `panel${index}` ? <CarRepairOutlinedIcon sx={{ fontSize: "1.5rem" }} /> : <CarRepairSharpIcon sx={{ fontSize: "1.5rem" }} />}>
            <Typography>{faq.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Read More Link */}
      <Link
        component="button"
        variant="body2"
        onClick={handleDialogOpen}
        sx={{
          display: "block",
          margin: "auto",
          mt: 2,
          fontSize: "1rem",
        }}
      >
        Read More
      </Link>

      {/* Dialog for Additional FAQs */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm" scroll="paper">
        <DialogTitle>More FAQs</DialogTitle>
        <DialogContent>
          {additionalFAQs.map((faq, index) => (
            <Accordion expanded={expandedDialog === `panel${index}`} onChange={handleDialogChange(`panel${index}`)} key={index}>
              <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`} expandIcon={expandedDialog === `panel${index}` ? <CarRepairOutlinedIcon sx={{ fontSize: "1.5rem" }} /> : <CarRepairSharpIcon sx={{ fontSize: "1.5rem" }} />}>
                <Typography>{faq.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogContactClick}>Contact us for more answers</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FAQs;
