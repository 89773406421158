import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0b5b4a",
    },
    light: {
      main: "#fff",
    },
    dark: {
      main: "#111",
    },
  },
  typography: {
    fontFamily: '"Poppins", "sans-serif"',
    h6: {
      color: "#000",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#0b5b4a",
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#042d23", // Your desired color
        },
      },
    },
  },
});

export default theme;
