import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./themes/theme.js";

import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Services from "./components/services/Services";
import WhyChooseUs from "./components/why-choose-us/WhyChooseUs.jsx";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import BrandShowcase from "./components/brandShowcase/BrandShowcase.jsx";

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav />
        <Header />
        <About />
        <Services />
        <WhyChooseUs />
        <BrandShowcase />
        <Contact />
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default App;
