import React from "react";
import Image_1 from "../../assets/about_us_1.png";
import { Typography, Grid, Box, Container, Button } from "@mui/material";

const About = () => {
  return (
    <Box component="section" id="about">
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "left", p: { md: 2 } }}>
              <Typography variant="h4" sx={{ color: "#1a6a5c", mb: 5 }}>
                PROVIDING EXCEPTIONAL AUTO REPAIR SINCE 2015
              </Typography>
              <Typography variant="h6" sx={{ color: "red", mb: 1 }}>
                Is your car or bus in need of expert repair or meticulous maintenance?
              </Typography>
              <Typography variant="body1">
                Gaiety Services is dedicated to ensuring your safety and that of your vehicle on the road. As a cornerstone of the Hyderabad community for over eight years, our auto repair shop has built a reputation for excellence. We specialize in everything from routine maintenance to comprehensive vehicle overhauls. Our team of expert technicians is committed to delivering swift and efficient service, while upholding the highest standards of quality and workmanship. At Gaiety Services, we're more than just mechanics; we're your trusted partners in automotive care.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ maxWidth: 300, width: "100%", mb: 2 }}>
              <img src={Image_1} alt="About" style={{ width: "100%", height: "auto" }} />
            </Box>
            <Button href="#contact" variant="contained" color="primary">
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
